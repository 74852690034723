a:link,
a:visited {
  color: #f50057;
  /* #99ccff; */
  /* text-decoration: none; */
}

body,
html {
  height: 100%;
  margin: 0;
}

img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

div.admin-bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }

  div.admin-bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}

:root {
  --space: 1.5em 0;
  --space: 2em 0;
}

.Site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Site-content {
  flex: 1 0 auto;
  padding: var(--space) var(--space) 0;
  width: 100%;
}

.Site-content:after {
  content: '\00a0';
  display: block;
  margin-top: var(--space);
  height: 0;
  visibility: hidden;
}

/* True 1080p (no scaling) */
@media screen and (min-width: 1920px) and (min-height: 900px) {
  .Landing {
    padding-top: 100px;
    padding-bottom: 100px;
  } 
}

/* True 1440p */
@media screen and (min-width: 2560px) and (min-height: 1300px) {
  .Landing {
    padding-top: 200px;
    padding-bottom: 200px;
  } 
}

/* True 4k */
@media screen and (min-width: 3840px) and (min-height: 2000px) {
  .Landing {
    padding-top: 400px;
    padding-bottom: 400px;
  } 
}
